
import { smoothScrollToTargetId } from '@/helpers/util'
import baseConstants from '~/store/base/-constants'
import OrganismPageLoader from "~/components/organisms/common/OrganismPageLoader.vue";
import {isMobile, isTablet} from "~/helpers/mobile/DeviceType";
import requestOfferConstants from "~/store/requestOffer/-constants";

export default {
  name: 'ProjectLayout',
  components: {OrganismPageLoader},
  data() {
    return {
      isScrolled: false,
      sections: []
    }
  },
  computed: {
    i18nActive() {
      return !!this.$store.state.base.meta.generalConfig.i18nActive
    },
    projects() {
      return this.$store.state.project.projects
    },
    isStaticProjectPage() {
      return !!this.projectStaticPage && this.projectStaticPage.template === 'Project';
    },
    sectionsData() {
      return this.$store.state.base.meta.projectConfig.sections
    },
    menuSectionsTags() {
      if (this.isStaticProjectPage) {
        return this.projectStaticPage.sections.filter((s) => s.menuLabel);
      }
      return this.sections.filter((s) => s.menuLabel)
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeSpace() {
      return this.$route.params.space
    },
    containsSpaceSlug() {
      return this.$route.params.space !== undefined
    },
    containsProjectSlug() {
      return this.$route.params.project !== undefined
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    instagramUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.$store.state.base.meta.social) return ''
      return this.$store.state.base.meta.social.facebookUrl
    },
    portfolioPageDisabled() {
      return this.$store.state.base.meta.generalConfig.portfolioPageDisabled || false
    },
    staticPages() {
      return this.generalConfig.staticPages || []
    },
    projectSpecificStaticPages() {
      return this.generalConfig.projectStaticPages || []
    },
    projectStaticPage() {
      const path = this.$route.path;
      const lastPathElement = path.split('/').pop();
      return this.projectSpecificStaticPages.find(p => p.path === '/'+lastPathElement);
    },
    showProjectSelect() {
      return !this.$route.params.space
    },
    linkPrefix() {
      if (this.portfolioPageDisabled && !this.containsProjectSlug) return '/'
      if (this.containsSpaceSlug) return `/project/${this.activeProject}/space/${this.activeSpace}`
      return `/project/${this.activeProject}`
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections();
        this.removeEventListeners();
        setTimeout(() => {
          this.initEventListeners();
        }, 50);
        if (this.$route.hash !== '') {
          document.querySelector(this.$route.hash).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    }
  },
  mounted() {
    this.sortSections()
    this.initEventListeners();
    if (this.$route.hash !== '') {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  beforeDestroy() {
   this.removeEventListeners();
   this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  },
  methods: {
    initEventListeners() {
      const self = this
      document.addEventListener('scroll', this.initScrollListener)
      document.querySelectorAll('a.navigation-link[href*="#"]').forEach((anchor) => {
        anchor.addEventListener('click', self.smoothScroll)
      })
    },
    removeEventListeners() {
      const self = this
      document.removeEventListener('scroll', this.initScrollListener)
      document.querySelectorAll('a.navigation-link[href*="#"]').forEach((anchor) => {
        anchor.removeEventListener('click', self.smoothScroll)
      })
    },
    initScrollListener() {
      this.isScrolled = window.scrollY > window.innerHeight;
      const scrollY = window.scrollY + 100;
      document.querySelectorAll('a.navigation-link[href*="#"]').forEach((link) => {
        link.classList.remove('active')
        const section = document.getElementById(`${link.href.split('#')[1]}`).parentElement
        if (section.offsetTop <= scrollY && section.offsetTop + section.offsetHeight > scrollY) {
          link.classList.add('active')
        }
      })
    },
    backToTop() {
      const sections = this.isStaticProjectPage ? [...this.projectStaticPage.sections] : [...this.sections];
      if (sections && sections.length && sections[0]) {
        smoothScrollToTargetId(`#${sections[0].hashAnchor}`);
      }
    },
    sortSections() {
      let sectionsData = [...this.sectionsData].map((s) => {
        const clone = { ...s }
        if (this.$route.name === 'SpecificSpace' && clone.type === 'Building') clone.order_no = 1
        if (this.$route.name === 'SpecificSpace' && ['home', 'hero'].includes(clone.hashAnchor))
          clone.order_no = 2
        return clone
      })
      if (this.$route.name === 'SpecificSpace') {
        sectionsData = sectionsData.filter((s) => !['home', 'hero'].includes(s.hashAnchor))
      }
      sectionsData = sectionsData.filter((s) => s.enabled)
      this.sections = sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1
        }
        if (a.order_no > b.order_no) {
          return 1
        }
        return 0
      })
    },
    smoothScroll(e) {
      e.preventDefault();
      document.querySelector(`#${e.target.href.split('#')[1]}`).scrollIntoView({
        behavior: 'smooth'
      })
      try{
        const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]')
        links.forEach((link) => {
          const activeLink = e.path[0];
          link.classList.remove('active')
          if (link === activeLink && !activeLink.classList.contains('active')) {
            link.classList.add('active')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    hashChecker(target) {
      if (target === window.location.hash) {
        smoothScrollToTargetId(target)
      }
    }
  }
}
